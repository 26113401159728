import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import ProseDark from "../../components/prose-dark"
import ProseLight from "../../components/prose-light"

class CommunicationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start: false,
      loading: true
    };
  }

  componentDidMount() {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    sleep(100).then(() => {
        this.setState({ loading: false });
    })
    sleep(300).then(() => {
        this.setState({ start: true });
    })

    // if (this.video) {
    //   this.video.addEventListener("loadeddata", () => {
    //     this.setState({ loading: false });
    //   });
    // }
  }

  componentWillUnmount() {
    // if (this.video) {
    //   this.video.removeEventListener("loadeddata", () => {
    //   });
    // }
  }
  
  render() {

  return(
  <Layout>
    <SEO title="General Communications" />
    <div className="bg-midnight w-full px-8 py-36">
    <div className="m-auto w-full container">
      <ProseDark>
        <div className="grid md:grid-cols-2 gap-12">

        <div>

        <div className="text-base mb-4 -mt-4">
          <Link className="inline-block no-underline hover:text-yellow-400 hover:underline text-white font-normal" to="/">Home</Link>
          <span className="opacity-50"> <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> General Communications</span>
        </div>
        <h1 className="text-4xl md:text-5xl text-yellow-400 leading-none !mb-0">General Communications</h1>
        <p className="!mt-4 text-yellow-400 italic text-3xl">Building your relationship with the public </p>
        </div>
        <div>

        <p>Special purpose districts need immediate communication, resident support, and community education; you can’t do that with just a newsletter, or posted signs, or even a static website.</p>
        <p>Communication is a building block of communities, and special purpose districts are created to serve communities. When residents can receive updates from service providers to their phone in an instant, and local disasters are live streamed on social media, older methods of communication no longer apply.</p>
        </div>
        </div>
      </ProseDark>
    </div>
    </div>
    <div className="bg-zinc-100 w-full px-8 pb-36">
    <div className="m-auto w-full container">
      <ProseLight>
        <iframe className="relative bottom-20 mx-auto w-full lg:w-2/3 aspect-video rounded-3xl shadow-2xl" src="https://www.youtube.com/embed/gV1h3EJq3IA?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>

        <div className="m-auto w-full lg:w-2/3 mt-12 leading-loose">

        {/* <h1 className="text-7xl leading-none text-center">Elections</h1> */}
        <p className="font-medium">Every good communication strategy begins with a communications plan. This plan should outline how best to reach your residents. Every resident is different, and a comprehensive approach can include printed materials, text and email updates, social media posts, and a website to point residents to as the source of the information they need. Your message is our priority, and your good stewardship is paramount to our strategy.</p>

        <p className="font-medium">Touchstone facilitates the methods your District and its consultants will use to engage your residents with the information necessary to foster ongoing trust with, and transparency from, the Board. By establishing the good faith with your community in the quiet times, you will have earned their trust when an emergency arises.</p>

        <p className="font-medium">It is important to get your story out first and best, and Touchstone would love to help. Contact us today for a communications consultation.</p>
        <p className="text-center">
          <Link className="transition-all inline-block rounded-full px-6 py-2 bg-yellow-500 hover:bg-yellow-400 text-black no-underline" to="/contact-us">Let's Schedule a Meeting</Link>
        </p>
        </div>

      </ProseLight>
    </div>
    </div>
  </Layout>
)
}
}

export default CommunicationPage
